.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.light-blue-bg{
  background-color: rgb(172 210 255 / 17%) !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pointer:hover {
  cursor: pointer !important;
}

.width90per {
  width: 90% !important;
}

.width100per {
  width: 100% !important;
}

.margin1per{
  margin:1%;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.gap1per{
  gap: 1%;
}

.white-text{
  color: #fff;
  font-family: 'Montserrat';
}

.black-text{
  color: var(--LabelPrimary, #000);
}

.light-blue-text{
  color: var(--SecondarySecondShade, #487AE8);
}

.primary-main-bg{
  background-color: var(--primarymain) !important;
}

*{
  font-family: 'Montserrat';
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: white;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primarymain);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.scroll-body{
  overflow: auto !important;
  height: 100vh !important;
}

body{
  overflow: auto;
}

.bg-clinic{
  background-color: #FFD1C9;
}

.bg-nursing{
  background-color: #C7E0B8;
  border-radius: 10px;
  height: 209px !important;
}

.bg-scan{
  background-color: #E9B0DC;
  border-radius: 10px;
  height: 209px !important;
}

.width-fit{
  width: fit-content !important;
}

.width30per{
  width: 30% !important;
}

.width75per{
  width: 75%;
}

.pagination-container{
  width: 100%;
}

.pagination-container ul {
  display: flex;
  width: 100%;
  margin: auto;
  gap: 20px;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.pagination-container li a {
  color: var(--backgroundprimary);
}

.pagination-container li {
  padding: 10px;
}

.pagination-container .selected {
  border-radius: var(--br-61xl);
  background-color: var(--primarymain);
  color: var(--backgroundprimary);
  width: 50px;
  height: fit-content;
  text-align: center;
}

.react-confirm-alert-body{
  font-family: 'Montserrat' !important;
  color: var(--color-black) !important;
  font-weight: 600;
}

.react-confirm-alert-button-group button, .savedDiv{
  background: var(--secondarymain) !important;
  font-family: 'Montserrat' !important;
  font-weight: 600;
}

.savedDiv{
  font-size: 19px;
  text-align: center;
}

.react-confirm-alert-button-group{
  justify-content: flex-end !important;
}

.react-confirm-alert-body{
  padding: 20px !important;
  border: var(--secondarymain) solid 2px;
}

.flip-img{
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.height-auto{
  height: auto !important;
}

.margin-top2rem{
  margin-top: 2rem;
}

.width25px{
  width: 25px;
}

.float-right{
  float: right;
}

.pusher-text{
  margin: auto;
  width: fit-content;
  color: var(--secondarymain);
}

.pusher-header{
  background-color: lightblue;
  padding: 13px;
  width: 89%;
}

.css-1wvake5 {
  height: 100vh;
}

.seo-logo{
  width: 102px;
  margin: 20px;
}

.logout-seo{
  bottom: 13px;
  position: absolute !important;
}

.blog-card{
  width: 80%;
}