
.doctor-name-placeholder,
.doctor-name-placeholder1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-name-placeholder1 {
  flex: 1;
  position: relative;
  font-weight: 600;
  width: 260px;
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(0.5vw + 8px);
}

.doctor-name-placeholder {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  border: 2px solid var(--secondarymain);
  box-sizing: border-box;
  height: 55px;
  flex-direction: row;
  padding: var(--padding-3xs);
  color: var(--secondarymain);
}

.image-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.specialty {
  position: relative;
  font-weight: 600;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: -webkit-auto;
  font-size: calc(7px + .5vw);
}

.specialty-placeholder,
.specialty-placeholder1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.specialty-placeholder1 {
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: calc(9px + .4vw);
}

.specialty-placeholder {
  align-self: stretch;
}

.cost,
.egp {
  position: relative;
  font-weight: 500;
}

.egp {
  font-weight: 600;
  color: var(--secondarymain);
  font-size: calc(0.7vw + 6px);
}

.price-placeholder {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width:100%;
  gap: var(--gap-3xs);
}

.location-icon {
  position: relative;
  width: 25px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.pindistance {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}

.location-name-placeholder {
  position: relative;
  font-size: var(--font-size-sm);
  font-weight: 600;
  height: 35px;
  max-width: 110px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-details {
  flex-shrink: 0;
  display: flex;
  color: var(--mid-gray);
  width: 100%;
}

.costlocation {
  flex-shrink: 0;
  flex-direction: row;
  gap: var(--gap-sm);
  font-size: var(--font-size-sm);
  gap: 10px;
  flex-wrap: wrap;
}

.book,
.book-button,
.costlocation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.book {
  position: relative;
  font-weight: 600;
  width: 85px;
  flex-shrink: 0;
}

.book-button {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--teritiarymain);
  height: 32px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  color: var(--backgroundprimary);
  cursor: pointer;
}

.star-rating-icon {
  position: relative;
  width: calc(15px + 0.9vw);
  object-fit: cover;
}

.doctor-card {
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid var(--secondarymain);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  gap: var(--gap-6xs);
  width: 23%;
}

.card-section-container{
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.clinic-div .div,
.tele-health-div .div,
.home-visit-div .div,
.doctor-cards {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.doctor-cards {
  flex-direction: row;
  text-align: center;
  gap: 1vw;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
}

.vector-icon {
  position: relative;
  width: 8px;
  height: 13.58px;
  object-fit: cover;
}

.prev {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  color: var(--backgroundquaternary);
}

.b {
  position: relative;
}

.number,
.number1 {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}

.div8 {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.next, .previous,
.next-5-pages,
.pagination-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-5-pages {
  width: 40px;
  height: 40px;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  color: var(--mid-gray);
}

.next, .previous,
.pagination-bar {
  flex-direction: row;
}

.next, .previous {
  border-radius: var(--br-61xl);
  background-color: var(--primarymain);
  height: 40px;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  color: var(--backgroundprimary);
}

.pagination-bar {
  flex-shrink: 0;
  padding: 0 280px;
  gap: var(--gap-8xs);
}

.logo-icon {
  position: relative;
  width: 126px;
  height: 84px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.for-doctors-items {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  font-size: var(--font-size-base);
}

.contact-us {
  position: relative;
  font-weight: 800;
}

.for-doctors-items1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-8xs);
  font-size: var(--font-size-base);
}

.clinic-div .frame, .clinic-frame {
  border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
  background-color: #ffd1c9;
  height: 208px;
  position: relative;
}

.book-your-doctor {
  position: relative;
  font-size: var(--font-size-5xl);
  font-weight: 600;
  color: var(--secondarymain);
}

.avoid-the-hassle {
  margin: 0;
}

.avoid-the-hassle-container {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.01em;
}

.tov {
  border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  padding: 33px;
  width: 75%;
}

.dr-isolated-2 {
  position: relative;
  height: 254px;
  object-fit: cover;
}

.home-isolated{
  position: relative;
  height: 297px;
  object-fit: cover;
  top: -45px;
}

.feature-banner {
  top: 34px;
  width: 99%;
  height: 251px;
  font-size: var(--font-size-base);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  position: relative;
}

.logo-icon1 {
  position: relative;
  width: 104px;
  height: 69px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.telehealth {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.search-bar1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}

.search-bar1 {
  border-radius: 0 0 0 var(--br-3xs);
  background-color: var(--primaryfirstshade);
  width: 140px;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}

.match-found {
  top: 154px;
  right: 1073px;
  border-radius: var(--br-3xs);
  background-color: var(--primarymain);
  min-width: fit-content;
  margin: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) 40px;
  box-sizing: border-box;
  font-size: var(--font-size-5xl);
  color: var(--backgroundprimary);
  height: 55px;
  width: 96%;
  font-size: calc(10px + .5vw);
}

.filter-title,
.specialist-gender1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.filter-title {
  border-bottom: 1px solid var(--mid-gray);
  align-items: flex-start;
  padding: var(--padding-3xs);
  color: var(--labelprimary);
}

.specialist-gender1 {
  align-items: center;
  padding: 0 var(--padding-3xs);
}

.genders,
.specialist-gender {
  align-self: stretch;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
}

.genders {
  gap: var(--gap-8xs);
  color: var(--labelprimary);
}

.specialist-gender {
  padding: 0 0 var(--padding-mini);
  gap: var(--gap-5xs);
  font-size: var(--font-size-base);
}

.search1 {
  align-self: stretch;
  box-sizing: border-box;
  height: 40px;
  display: flex;
}

.search1,
.search2-clinic {
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  border: 1px solid var(--mid-gray);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-41xl);
}

.search-button-filter,
.search-fields,
.search2-clinic {
  align-self: stretch;
  display: flex;
}

.search-fields {
  border-bottom: 1px solid var(--mid-gray);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-xl);
  gap: var(--gap-3xs);
  font-size: var(--font-size-base);
}

.search-button-filter {
  border-radius: var(--br-3xs);
  background-color: var(--teritiarymain);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-3xs);
  gap: var(--gap-6xs);
  margin: auto;
  width: 47%;
  cursor: pointer;
}

.clear {
  flex: 1;
  position: relative;
  font-weight: 600;
}

.clear-button {
  border-radius: var(--br-3xs);
  background-color: var(--negativeactionprimary);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-3xs);
  gap: var(--gap-6xs);
  margin: auto;
  width: 47%;
  cursor: pointer;
}

.action-buttons,
.search-filter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.action-buttons {
  align-self: stretch;
  flex-direction: row;
  text-align: center;
  color: var(--backgroundprimary);
}

.filter-search-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 34px;
  min-width: fit-content;
  width: 32%;
  height: fit-content;
}

.search-filter {
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  border: 2px solid var(--secondarymain);
  box-sizing: border-box;
  width: 341px;
  flex-direction: column;
  padding: 4%;
  color: var(--mid-gray);
  width: 96%;
  margin: auto;
}

.submit-question-button,
.text10 {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}

.text10 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-10xs);
}

.submit-question-button {
  border-radius: var(--br-31xl);
  background-color: var(--teritiarymain);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-3xs);
  font-size: calc(10px + .2vw);
}

.clinic-div, .home-visit-div, .tele-health-div {
  position: absolute;
  top: 122px;
  bottom: 210px;
  overflow-y: auto;
  background-color: var(--backgroundprimary);
  width: 100%;
  overflow-y: auto;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--labelprimary);
  font-family: var(--font-montserrat);
  top: 25px;
  flex-wrap: wrap;
  min-height: calc(100vh - 314px);
}

.cards-doctors-div {
  width: 65%;
}

.img-container {
  display: flex;
  margin: auto;
  top: -45px;
  position: relative;
}